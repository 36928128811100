<template>
    <div class="p-relative">

        <v-form class="wrapperForm">
            <v-row dense>

                <!--Обертка для секции-->
                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title"> {{ $t("Отчеты") }} </div>

                            <v-list 
                                class="striped-list-card"
                                flat
                                dense
                            >
                                <v-list-item
                                    v-for="(item, i) in availableReports"
                                    :key="i"
                                    :value="item"
                                    @click="reportClick(item)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t(item.text) }}
                                        </v-list-item-title>
                                    </v-list-item-content>

                                </v-list-item>
                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-col>

                <!--Обертка для секции-->
                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title"> {{ $t("Выгрузки") }} </div>

                            <v-list 
                            class="striped-list-card"
                            flat
                            dense
                            >
                                <v-list-item
                                    v-for="(item, i) in availableExports"
                                    :key="i"
                                    :value="item"
                                    @click="exportClick(item)"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t(item.text) }}
                                        </v-list-item-title>
                                    </v-list-item-content>

                                </v-list-item>
                            </v-list>

                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </v-form>

        <SelectExportParamsDlg ref="SelectExportParamsDlgRef" />

    </div>
</template>

<script>
import { httpAPI, errorHandler } from "@/api/httpAPI";
import SelectExportParamsDlg from '@/components/saylau/dialogs/SelectExportParamsDlg.vue';

export default {
    name: "ElectionPartsInfo",
    components: {
        SelectExportParamsDlg
    },
    computed: {
        availableReports() {
            return [
                { exportType: 0, text: 'Отчет_по_территориальным_единицам' },
                { exportType: 1, text: 'Сведения_об_избирательных_участках' },
                { exportType: 2, text: 'Сведения_о_границах_избирательных_участков' },
                { exportType: 3, text: 'Отчет_о_национальном_составе_избирателей' },
                { exportType: 4, text: 'Отчет_о_возрастном_составе_избирателей' },
                { exportType: 7, text: 'Отчет_о_гендерном_составе_избирателей' }
            ];
        },
        availableExports() {
            return [
                { type: 'Documents.C2.Euol_1_OL', text: 'Выгрузка_для_ЦИК', param: 'period-param' }
            ];
        }
    },
    data: () => {
        return {

        }
    },
    methods: {
        async reportClick(reportItem) {
            let onResolve = (response) => {

                let filename = "Export.xlsx";
                let headerLine = response.headers['content-disposition'];

                if (headerLine) {
                    let token = headerLine.split(';').map(t => t.trim()).find(t => t.startsWith("filename*=UTF-8"));

                    if (token)
                        filename = decodeURIComponent(token.replace("filename*=UTF-8''", ""));
                }

                this.$notify.success(this.$t("Загрузка_файла_filename_завершена.message", { filename: filename }));
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            };

            let onReject = (error) => {
                errorHandler(error);
            };

            let exportParams = await this.$refs.SelectExportParamsDlgRef.open({ exportType: reportItem.exportType });

            if (exportParams.isCancelled === true)
                return;

            let request = httpAPI.post(`saylau/export/spreadsheet`, exportParams, { responseType: 'blob', skipErrorHandler: true });

            this.$notify.async(request, onResolve, onReject, this.$t("Подготовка_отчета"), { icons: { enabled: true } });
        },
        async exportClick() {
            let onResolve = (response) => {

                let filename = "Export.zip";
                let headerLine = response.headers['content-disposition'];

                if (headerLine) {
                    let token = headerLine.split(';').map(t => t.trim()).find(t => t.startsWith("filename*=UTF-8"));

                    if (token)
                        filename = decodeURIComponent(token.replace("filename*=UTF-8''", ""));
                }

                this.$notify.success(this.$t("Загрузка_файла_filename_завершена.message", { filename: filename }));
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            };

            let onReject = (error) => {
                errorHandler(error);
            };

            let exportParams = await this.$refs.SelectExportParamsDlgRef.open({ exportType: 5 });

            if (exportParams.isCancelled === true)
                return;

            let request = httpAPI.post(`saylau/export/mdb`, exportParams, { responseType: 'blob', skipErrorHandler: true });

            this.$notify.async(request, onResolve, onReject, this.$t("Подготовка_данных"), { icons: { enabled: true } });
        }
    },
    mounted() {

    },  
}
</script>